<template>
  <div class="w-100 text-center mt-3">
    <h4>Происходит переход.<br />Пожалуйста, подождите.</h4>
  </div>
</template>

<script>
import {createAuthManager, createAuthTokenManager} from "@/utils/di";
import {useRoute} from "vue-router";

export default {
  name: "CallbackLogin",
  async created() {
    const route = useRoute();
    const code = route.query.code;
    const state = route.query.state;
    if (typeof code === "string") {
      try {
        const manager = createAuthManager();
        const bearerToken = await manager.esiaLogin(code, state);
        createAuthTokenManager().create(bearerToken);
        sessionStorage.setItem("loggedIn", "autologin");
        return this.$router.replace({name: "dashboard"});
      } catch (e) {
        console.error(e);
        return this.$router.replace({name: "login"});
      }
    }
  }
};
</script>
